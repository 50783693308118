import { Box, Card, Container, Typography } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";

const FooterWrapper = experimentalStyled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(3)};
`
);

function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box
          py={3}
          display={{ xs: "block", md: "flex" }}
          alignItems="center"
          textAlign={{ xs: "center", md: "left" }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1">
              &copy; 2023 - ROTD Auctions
            </Typography>
          </Box>
          <Typography sx={{ pt: { xs: 2, md: 0 } }} variant="subtitle1">
            Support:{" "}
            <a href="mailto:Info@RightOfTheDot.com">Info@RightOfTheDot.com</a>
          </Typography>
          <Typography sx={{ pt: { xs: 2, md: 0 } }} variant="subtitle1">
            Powered by amplify.io
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
